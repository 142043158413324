<template>
  <div class="home">
    <v-container class="grey lighten-5">
      <v-row>
        <v-col>
          <h2 class="font-weight-light mb-3">Jouw Profiel</h2>
          <p>Pas hier je zichtbare naam aan</p>
          <v-form>
            <v-text-field
                v-model="user.data.name"
                label="Gebruikersnaam"
                required
            ></v-text-field>
            
                <v-btn @click="updateUser()" color="primary">Update je gegevens</v-btn>
            {{errorMessage}}
        </v-form>
        </v-col>

      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { usersRef, walksRef} from "../store/firestore";
//import store from "../store";

export default {
  name: "Home",
  components: {},
  data: () => ({
      userData:{},
      errorMessage :''
  }),
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
    }),
  },
  created() {

  },
  methods: {
    updateUser(){
        var that = this;
        
        var userRef = usersRef.doc(that.user.data.id);
            userRef
                .set({
                      naam: that.user.data.name
                    },
                    { merge: true }
                )
                .then(function () {
                    // zoek alle naam van mij in 
                    // walks + partner
                    // alle uid's
                    var alleWalksRef = walksRef.where("uid","==", that.user.data.uid).get();
                    // alle uid's in partner
                    var allePartnerRef = walksRef.where("partner.uid", "==", that.user.data.uid).get();


                    alleWalksRef.then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                                 walksRef.doc(doc.id).set({
                                     naam:that.user.data.name
                                 }, {merge:true})
                            });
                        })
                    .catch(function (error) {
                            console.log("Error getting documents: ", error);
                    }); 
                    
                    allePartnerRef.then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            var partner = doc.data().partner;
                            partner.naam = that.user.data.name;
                                 walksRef.doc(doc.id).set({
                                     partner : partner
                                 }, {merge:true}
                                 )
                            });
                        that.errorMessage = "Gelukt!"
                    })
                    .catch(function (error) {
                            console.log("Error getting documents: ", error);
                    }); 
                    
                    
                })
                .catch(function(error) {
                     // An error happened.

                       that.errorMessage = error;
                           //that.$router.push(that.$route.query.from || '/')                        
                });
               
              
         
    }, // einde update 
  }
};
</script>